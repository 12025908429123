<template>
    <div class="main-container">
      <div class="banner">
      <img src="./../assets/logo-light.png" alt="logo home" />
      <h1>Mentions légales</h1>
    </div> 
    <div class="legal-container">
      <p>Le cabinet FACIL’IMMO est spécialisé dans la transmission de commerces, entreprises et immobilier associé.</p>
      <p>En choisissant d’accéder et de naviguer sur le site internet https://www.cabinet-facilimmo.com, l’internaute (ci-après dénommé l’ « Utilisateur ») accepte, expressément et irrévocablement, les termes ci-après.</p>
      <p>Par le terme Utilisateur, il faut entendre toute personne physique majeure capable, ou toute personne morale, utilisant les services qui sont proposés sur le site internet https://www.cabinet-facilimmo.com, pouvant être soit un particulier, soit un professionnel, étant précisé que toute stipulation des présentes conditions générales qui n’est pas opposable aux particuliers en vertu de la réglementation en vigueur aura vocation à ne s’appliquer qu’aux professionnels. </p>
      
      <h2>1. MENTIONS LEGALES</h2>
      <p>Le présent site internet https:// www.cabinet-facilimmo.com est la propriété de la société SARL FACIL’IMMO dont le siège social est situé au 88 Rue Edmond Rostand, 13006 Marseille, immatriculée au registre du commerce et des sociétés de Marseille sous le numéro 528 139 249.</p>
      <p>Directeur de la publication : Mr Eddie REBAI fondateur de la société FACIL’IMMO.</p>
      <p>Le site est hébergé par la société OVH, SAS au capital de 10 069 020 € RCS Lille Métropole 424 761 419 00045 Code APE 2620Z N° TVA : FR 22 424 761 419 Siège social : 2 rue Kellermann - 59100 Roubaix - France </p>
      
      <h2>2. OBJET DU SITE INTERNET </h2>
      <p>Les présentes conditions générales d’utilisation ont pour objet de définir les modalités de mise à disposition du site https:// www.cabinet-facilimmo.com et les conditions d’utilisation par l’Utilisateur. </p>
      
      <h2>3. ACCEPTATION DES CONDITIONS GENERALES D’UTILISATION </h2>
      <p>L’accès au site https:// www.cabinet-facilimmo.com par l’Utilisateur est subordonné à l’acceptation sans réserve des conditions générales d’utilisation.</p>
      <p>La société FACIL’IMMO se réserve le droit de modifier et de mettre à jour les conditions générales d’utilisation à tout moment. Celles-ci entreront en vigueur à compter de leur date de mise en ligne sur le site de Cabinet FACIL’IMMO. Les nouvelles conditions générales d’utilisation s’imposent à l’Utilisateur. </p>
      
      <h2>4. PROPRIETE INTELLECTUELLE </h2>
      <p>Le contenu du site internet https:// www.cabinet-facilimmo.com est protégé par les lois françaises et internationales relatives à la propriété intellectuelle. </p>
      <p>Tous les éléments contenus sur ce site, notamment les photographies, logos, textes, slogans, dessins, images, ainsi que toutes œuvres intégrées dans le site sont la propriété exclusive de la société FACIL’IMMO ou de tiers ayant autorisé cette dernière à les utiliser. </p>
      <p>Toute reproduction, représentation, utilisation ou modification, par quelque procédé que ce soit et sur quelque support que ce soit, de tout ou partie du contenu du site, de tout ou partie des différentes œuvres qui le composent, sans avoir obtenu l'autorisation préalable de la directrice de la publication est strictement interdite, constitue un délit de contrefaçon et peut donner suite à des poursuites judiciaires. </p>
      
      <h2>5. RESPONSABILITE </h2>
      <p>La société FACIL’IMMO ne pourra être tenue pour responsable pour quel que dommage que ce soit tant direct qu'indirect, résultant d'une information contenue sur ce site. </p>
      <p>L'Utilisateur s'engage à ne transmettre sur ce site aucune information pouvant entraîner une responsabilité civile ou pénale et s'engage à ce titre à ne pas divulguer via ce site des informations illégales, contraires à l'ordre public ou diffamatoires.</p> 
      <p>L'utilisation du présent site implique la connaissance et l'acceptation par l’Utilisateur des caractéristiques et des limites technologiques qui y sont liées, l'absence de protection de certaines données contre des détournements éventuels ou piratage et risques de contamination par des éventuels virus circulants sur le réseau internet.</p>
      <p>La société FACIL’IMMO décline toute responsabilité en cas de mauvaise utilisation ou d'incident lié à l'utilisation par un internaute de l'ordinateur, de l'accès à internet, de la maintenance ou du dysfonctionnement des serveurs, de la ligne téléphonique ou de toute autre connexion technique, ou incompatibilité matérielle et de l'envoi des formulaires à une adresse erronée ou incomplète, d'erreurs informatiques quelconques ou défauts constatés sur le site.</p>
      <p>La société FACIL’IMMO décline toute responsabilité en cas de retard de mise à jour, de même qu'en cas d'interruption ou d'indisponibilité temporaire du site internet.</p>
      <p>La société FACIL’IMMO se réserve le droit de corriger, à tout moment et sans préavis, le contenu de ce site. </p>
      <p>L’Utilisateur s’engage à solliciter les autorisations préalables et indispensables à la collecte des données personnelles auprès des personnes concernées. </p>
      
      <h2>6. INFORMATIQUE ET LIBERTE - PROTECTION DES DONNEES PERSONNELLES</h2>
      <p>FACIL’IMMO accorde beaucoup d’importance à la vie privée de ses utilisateurs. Les présentes conditions générales d’utilisation informent l’Utilisateur du site internet sur la manière dont FACIL’IMMO collecte, utilise et divulgue les données personnelles au regard de la règlementation en vigueur. </p>
      <h3>6.1 Collecte des données </h3>
      <p>FACIL’IMMO collecte les données personnelles de manière directe lorsque l’Utilisateur navigue sur le site internet.</p>
      <p>Les données personnelles collectées peuvent notamment inclure : </p>
      <ul>
      <li>des données de contact, telles que le nom, l’adresse e-mail, l’adresse postale, et le numéro de téléphone ; </li>
      <li>des commentaires, et autres informations fournis par l’Utilisateur, y compris les requête de recherches, questions et informations; </li>
      <li>les données d’utilisation recueillies automatiquement du fait de la navigation sur le site internet, telles que les fonctions utilisées, les configurations sélectionnées, les données sur lesquelles l’Utilisateur a cliqué, y compris la date et l’heure et les pages consultées, les termes recherchés, les pages visitées et recherchées sur le site internet ; </li>
      <li>les informations concernant l’ordinateur, l’environnement de connexion, à savoir l’adresse IP, le type et la version du navigateur, le système d’exploitation, et autres logiciels installés dans votre environnement, la plateforme mobile, le ou les identifiants techniques, rapports d’erreur et données d’exécution ; </li>
      <li>les informations géographiques basées sur la localisation de l’Utilisateur pour fournir des contenus plus pertinents en fonction de votre situation géographique ; </li>
      <li>des entreprises avec lesquelles FACIL’IMMO offre des services communs ; et/ou </li>
      <li>des sources accessibles publiquement et auprès des fournisseurs de données de la part desquels FACIL’IMMO a obtenu des données afin de valider et de compléter les informations en sa possession. </li>
      </ul>
      <p>FACIL’IMMO collecte ces données par le biais de ses serveurs et de l’utilisation de cookies et d’autres technologies comme indiqué ci-après. </p>
      <p>FACIL’IMMO peut utiliser des cookies et d’autres technologies pour stocker les préférences et configurations de l’Utilisateur, fournir des publicités ciblées et analyser les opérations du site. </p>
      <h3>6.2 Utilisation des données </h3>
      <p>Les données personnelles de l’Utilisateur constituent des données confidentielles.</p>
      <p>Elles ne sont accessibles qu’à la société FACIL’IMMO et ne sont utilisées que dans le cadre des finalités pour lesquelles l’Utilisateur les a communiquées ainsi à la société FACIL’IMMO, à savoir pour : </p>
      <ul>
        <li>répondre à la demande formulée par l’Utilisateur (accompagnement dans la cession/acquisition d’une entreprise, candidature, valorisation, demande d’informations) et lui proposer des services adaptés par téléphone, courrier postal ou électronique, </li>
        <li>envoyer par courrier électronique à l’Utilisateur des alertes correspondant aux critères de recherche communiqués, </li>
        <li>envoyer par courrier électronique à l’Utilisateur des infolettres ("newsletter") sur l’activité de FACIL’IMMO,</li>
        <li>répondre à des demandes, recherches, commentaires et préoccupations ; </li>
        <li>notifier les modifications, mises à jour et autres annonces relatives aux services proposés, </li>
      </ul>
      <p>Certaines informations demandées dans les formulaires ont un caractère obligatoire et sont signalées par un astérisque. Si l’Utilisateur choisi de ne pas les communiquer, FACIL’IMMO ne pourra pas traiter la demande. </p>
      <h3>6.3 Partage des données </h3>
      <p>Afin d’assurer le traitement des informations et pour fournir à l’Utilisateur le service attendu (ex : réalisation d’une transaction ou répondre à une demande), et dans le strict respect des présentes conditions générales d’utilisation et de toute autre mesure de confidentialité et de sécurité appropriée, les données collectées à travers le site internet sont également accessibles </p>
      <ul>
        <li>aux fournisseurs de services informatiques pour des raisons exclusivement techniques, lesquels assurent leur traitement technique ainsi que leur hébergement et la modération ou le support technique,</li> 
        <li>aux chargés d’affaires et représentants de FACIL’IMMO. </li> 
      </ul>
      <h3>6.4 Obligations et responsabilité </h3>
      <p>FACIL’IMMO veille et s’engage à garantir la protection et la sécurité des données personnelles qui lui sont confiées par l’Utilisateur du site internet.</p>
      <p>FACIL’IMMO s'engage à :</p>
      <ul>
        <li>respecter les lois et réglementations applicables en matière de protection des données personnelles, c'est-à-dire le Règlement européen 2016/679 du 27 avril 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données (RGPD) ; </li>
        <li>traiter de telles données uniquement si cela est requis pour l'exécution des finalités listées ci-après et tel qu'autorisé ou exigé par la loi ; </li>
        <li>préserver la sécurité, l'intégrité et la confidentialité des données personnelles; </li>
        <li>prendre les mesures de sécurité organisationnelles, physiques et techniques appropriées afin de protéger les données personnelles, </li>
        <li>n'effectuer de transfert des données personnelles en dehors du territoire de l'Union européenne qu'avec l'autorisation préalable du client et dans un cadre sécurisé conformément aux exigences de la législation applicable, c'est-à-dire soit vers des pays présentant un niveau de protection dit « adéquat » au sens des autorités européennes de protection des données personnelles (CNIL), soit vers des entités (Sociétés affiliées, Sous-traitants) ayant signé des clauses contractuelles types telles qu'édictées par les autorités européennes ;</li> 
        <li>veiller à ce que les personnes autorisées à traiter les données à caractère personnel s’engagent à respecter la confidentialité ou soient soumises à une obligation légale appropriée de confidentialité et reçoivent la formation nécessaire en matière de protection des données à caractère personnel. </li>
      </ul>
      <h3>6.5 Droits des personnes physiques concernées par le traitement des données à caractère personnelles </h3>
      <p>Conformément à la loi Informatique et libertés N° 78-017 du 6 janvier 1978 complétées par le règlement européen de protection des données (RGPD), les personnes physiques disposent d’un droit d’accès aux données les concernant, de rectification, d’interrogation, de potabilité des données, un droit à l’oubli, un droit à la limitation du traitement, le droit de retirer son consentement, ainsi qu’un droit d’opposition pour motif légitime et à la prospection. </p>
      <p>Ainsi, en application de la réglementation applicable aux données à caractère personnel, les personnes physiques disposent notamment des droits suivants : </p>
      <ul>
        <li>ils peuvent exercer leur droit d'accès, pour connaître les données personnelles les concernant,</li> 
        <li>si les données à caractère personnel détenues par FACIL’IMMO sont inexactes, ils peuvent demander la mise à jour des informations,</li>  
        <li>ils peuvent demander la suppression de leurs données à caractère personnel. </li> 
      </ul>
      <p>Ces droits s’exercent par courrier postal accompagné d’une copie d’une pièce d’identité envoyée à l’adresse postale de la société FACIL’IMMO située au 88 Rue Edmond Rostand, 13006 Marseille.</p>
      <p>Les personnes physiques disposent également d’un droit d’introduire une réclamation auprès de la CNIL, si elles considèrent que le traitement de données à caractère personnel la concernant constitue une violation de la réglementation en vigueur. </p>
      <h3>6.6 Sort des données </h3>
      <p>La société FACIL’IMMO conserve les données personnelles de l’Utilisateur aussi longtemps que nécessaire dans le cadre de la réalisation de ses activités ou à d’autres fins essentielles telles que le respect de la règlementation en vigueur, de ses obligations légales, la tenue d’archives commerciales et financières, le règlement de différends, le maintien de la sécurité, la détection et la prévention de la fraude et d’abus, et l’exécution de sa mission pour l’Utilisateur. </p>
      <h3>6.7 Stockage des données </h3>
      <p>Les données personnelles sont stockées en France sur un serveur sécurisé de la société OVH, 2 rue Kellermann - 59100 Roubaix - France</p>
      <h3>6.8 Sécurité des données </h3>
      <p>FACIL’IMMO prend toutes les précautions pour préserver la sécurité des données personnelles afin, notamment, d’empêcher leur divulgation à des tiers non-autorisés, en toute cohérence avec les normes de protection imposées par la loi en vigueur. </p>
      <p>Dans l’hypothèse où les données d’un Utilisateur font l’objet du transfert auprès de fournisseurs et/ou sous-traitants (notamment pour leur hébergement), FACIL’IMMO veille à ce que ces derniers présentent un niveau de sécurité conforme aux standards requis.</p>
      <h3>6.9 Délégué à la Protection des Données </h3>
      <p>Le représentant FACIL’IMMO à la Protection des Données est Mr Eddie REBAI.</p>

      <h2>7. COOKIES </h2>
      <p>Lors de la consultation du site Internet, des informations relatives à la navigation du terminal de l’Utilisateur (ordinateur, tablette, smartphone, etc.), sont susceptibles d’être enregistrées dans des fichiers « Cookies » installés sur ledit terminal, sous réserve des choix que l’Utilisateur aura exprimés concernant les cookies et qu’il peut modifier à tout moment. </p>
      <h3>7.1 Définition d’un cookie </h3>
      <p>Un cookie est un petit fichier texte enregistré dans un espace dédié du disque dur du terminal (ordinateur, tablette, smartphone, etc.) de l’Utilisateur, à l’occasion de la consultation d’un service en ligne. Il permet à son émetteur d’identifier le terminal dans lequel il est enregistré, pendant la durée de validité ou d’enregistrement du cookie. </p>
      <h3>7.2 Finalité des cookies </h3>
      <p>Les cookies sont utilisés par FACIL’IMMO afin de rendre plus efficaces les visites de l’Utilisateur sur son site internet. Les cookies permettent également d’améliorer la navigation sur le site et de comprendre le comportement d’achat de l’Utilisateur et ses besoins au regard des services rendus. </p>
      <h3>7.3 Utilisation des cookies </h3>
      <p>FACIL’IMMO utilise les cookies pour </p>
      <ul>
        <li>mémoriser les informations de connexion,</li> 
        <li>identifier un Utilisateur lorsqu’il se connecte au site internet, </li>
        <li>collecter des informations analytiques pour, par exemple, compter le nombre de visiteurs sur le site internet et identifier les pages du site qui sont visitées le plus souvent.</li> 
        <li>Utiliser les données d’analyse collectées à des fins marketing et de planification des ressources. </li>
      </ul>
      <h3>7.4 Informations collectées grâce aux cookies </h3>
      <p>Les cookies permettent à FACIL’IMMO de recueillir des renseignements sur la façon dont l’Utilisateur navigue sur le site Web. </p>
      <h3>7.5 Types de cookies </h3>
      <p>Cookies de session – Les cookies de ce type sont temporairement stockés sur l’ordinateur ou périphérique de l’Utilisateur pendant une session de navigation. Ils sont effacés du terminal de l’Utilisateur à la fin de la session de navigation.</p>
      <p>Cookies permanents – Les cookies de ce type demeurent plus longtemps sur le terminal de l’Utilisateur. Les cookies permanents permettent de reconnaître l’Utilisateur lorsque FACIL’IMMO a besoin de savoir qui est l’Utilisateur pour plus d’une session de navigation. </p>
      <h3>7.6 Gestion des cookies </h3>
      <p>Le menu de la plupart des navigateurs comporte des options permettant à l’Utilisateur de gérer ses cookies en réglant ou en configurant ses paramètres de cookies en fonction du navigateur</p>
      <ul>
        <li>Pour Internet Explorer™ : <a target="_blank" href="http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies">http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies</a> </li>
        <li>Pour Safari™ : <a target="_blank" href="https://support.apple.com/kb/PH21411?locale=fr_FR&viewlocale=fr_FR">https://support.apple.com/kb/PH21411?locale=fr_FR&viewlocale=fr_FR</a> </li>
        <li>Pour Chrome™ : <a target="_blank" href="http://support.google.com/chrome/bin/answer.py?hl=fr&hlmr=en&answer=95647">http://support.google.com/chrome/bin/answer.py?hl=fr&hlmr=en&answer=95647</a> </li>
        <li>Pour Firefox™ : <a target="_blank" href="http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies">http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies</a> </li>
      </ul>
      <p>En principe, le navigateur offre à l’Utilisateur la possibilité : </p>
      <ul>
        <li>d’afficher ses cookies ; </li>
        <li>d’autoriser les cookies ; </li>
        <li>de désactiver tous les cookies, ou uniquement des cookies précis ; </li>
        <li>de désactiver tous les cookies lorsqu’il ferme son navigateur ; </li>
        <li>de bloquer les cookies ; </li>
        <li>d’être informé lorsqu’il reçoit un cookie.</li>
      </ul>
      <p>Si l’Utilisateur décide de bloquer certains cookies, il se peut qu’il ne puisse plus s’inscrire, se connecter, ou accéder à certaines parties ou utiliser pleinement le site internet. En d’autres termes, certaines des fonctionnalités du site internet seront désactivées et la navigation risque également d’en être affectée. </p>
      <p>Pour en savoir plus sur les cookies, nous vous invitons à consulter les pages suivantes : </p>
      <ul>
        <li><a href="http://www.allaboutcookies.org/fr/" target="_blank">http://www.allaboutcookies.org/fr/</a> </li>
        <li><a href="http://www.cnil.fr/vos-libertes/vos-traces/les-cookies/" target="_blank">http://www.cnil.fr/vos-libertes/vos-traces/les-cookies/</a> </li>
      </ul>
      
      <h2>LIENS HYPERTEXTES </h2>
      <p>Les sites extérieurs à la société FACIL’IMMO ayant un lien hypertexte avec le présent site ne sont pas sous contrôle de la société FACIL’IMMO, qui décline par conséquent toute responsabilité quant à leur contenu. </p>
      <p>Aucun contrôle n’étant exercé par la société FACIL’IMMO sur ces ressources externes, l’Utilisateur est seul responsable de leur utilisation. </p>

      <h2>9. LITIGES </h2>
      <p>Les présentes conditions et la relation entre la société FACIL’IMMO et les utilisateurs seront soumises au droit français. </p>
      <p>Toute interprétation, contestation ou revendication liée aux présentes conditions générales doit, en premier lieu, et dans la mesure du possible, être réglé au moyen de négociations amiables. A défaut d'accord amiable, tout différent est soumis aux Tribunaux compétents. </p>

    </div>
  </div>
</template>

<script>
export default {
    name : 'LegalNotices'
}
</script>

<style lang="scss" scoped>
  .banner {
    background-image: url('./../assets/banner-home.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  h1 {
    text-align: center;
    color:#fff;
    font-size:40px;
    font-weight:500;
    margin:0;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top:20px;
  }

  .banner  img {
    width:150px;
  }

  .legal-container {
    padding:80px 0;
    width:1200px;
    max-width: 90%;
    margin:0 auto;
  }

  h2 {
    color:$main-color;
    font-size:30px;
    font-weight: 500;
    margin:40px 0;
  }

  h3 {
    color:$main-color;
    font-size:20px;
    font-weight: 500;
    margin:20px 0;
  }

  p, li {
    color:$text-color;
    font-size: 15px;
    text-align: justify;
  }

  .legal-container a {
    color:$text-color;
    text-decoration: none;
  }

  .legal-container a:hover {
    text-decoration: underline;
  }
  

  @media screen and (max-width:768px) {
    h1 {
      font-size:30px;
    }
  }
</style>